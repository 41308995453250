import React, { useState, useEffect } from "react"
import CssBaseline from "@mui/material/CssBaseline"
import { FullPageSpinner } from "./components/common/fullpage-spinner"
import { useAuth0 } from "@auth0/auth0-react"
import ApiClient from "./axios"
import { useSelector, useDispatch } from "react-redux"
import { fetchUserData } from "./store/user-actions"
import { useNavigate } from "react-router"

const AuthenticatedApp = React.lazy(() => import("./authenticated-app")),
    UnauthenticatedApp = React.lazy(() => import("./unauthenticated-app"))

// En ese archivo se hace la autentificación del usuario conectándose a Auth0
// Hay excepciones para las vistas de crear cuenta, catálogo y las vistas asociadas al setup de Shopify
// Si se requiere una vista que no requiera autentificación, se debe agregar en la variable unauthenticatedView
// Vistas autentificadas y no autentificadas tienen router separados

const App = () => {
    const { isAuthenticated, getIdTokenClaims, user, logout } = useAuth0(),
        [addedToken, setAddedToken] = useState(false),
        dispatch = useDispatch(),
        loadingUser = useSelector((state) => state.user.loadingUser),
        userError = useSelector((state) => state.user.userError),
        navigate = useNavigate(),
        redirectUrl = localStorage.getItem("redirectUrl"),
        unauthenticatedView =
            window.location.pathname.includes("shopify") ||
            window.location.pathname === "/create-account" ||
            window.location.pathname.includes("catalog/")

    useEffect(() => {
        if (isAuthenticated) {
            // token to request if authenticated
            getIdTokenClaims()
                .then((res) => {
                    const token = res.__raw
                    ApiClient.defaults.headers.common["token"] = token
                    setAddedToken(true)
                    localStorage.setItem("token", token)

                    if (redirectUrl && !unauthenticatedView) {
                        localStorage.removeItem("redirectUrl")
                        navigate(redirectUrl)
                    }
                })
                .catch((err) => console.error(err))
        } else if (!redirectUrl && !unauthenticatedView) {
            localStorage.setItem("redirectUrl", window.location.pathname)
            navigate("/")
        } else {
            ApiClient.defaults.headers.common["token"] = undefined
            localStorage.removeItem("token")
            localStorage.removeItem("user")
            localStorage.removeItem("tenant")
            localStorage.removeItem("role")
        }
    }, [getIdTokenClaims, isAuthenticated, setAddedToken])

    useEffect(() => {
        if (user && addedToken) {
            dispatch(fetchUserData(user.email))
        }
    }, [addedToken, user, dispatch])

    useEffect(() => {
        if (userError) {
            localStorage.removeItem("user")
            localStorage.removeItem("tenant")
            localStorage.removeItem("role")
            logout({ returnTo: window.location.origin })
        }
    }, [userError])

    return (
        <>
            <CssBaseline />
            {loadingUser ? (
                <FullPageSpinner />
            ) : (
                <React.Suspense fallback={<FullPageSpinner />}>
                    {addedToken && isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}
                </React.Suspense>
            )}
        </>
    )
}

export default App
