import ChatMessages from "./chat.messages"
import SigninMessages from "./signin.messages"
import InitialSetupMessages from "./initial-setup.messages"
import CreateAccount from "./create-account.messages"
import ClientList from "./client-list.messages"
import TemplateMessages from "./template.messages"
import UserMessages from "./user.messages"
import CheetabotMessages from "./cheetabot.messages"
import ChasesMessages from "./chases.messages"
import QuickTextsMessages from "./quick-texts.messages"
import BoardMessages from "./boards.messages"
import IntegrationMessages from "./integrations.messages"
import SalesMessages from "./sales.messages"
import DailyClientsMessages from "./daily-clients.messages"
import VisitMessages from "./visits.messages"
import FidelizationMessages from "./fidelization.messages"
import SatisfactionMessages from "./satisfaction.messages"
import CatalogMessages from "./catalog.messages"
import FlowsMessages from "./flows.messages"
import DashboardsMessages from "./dashboards.messages"
const messages = {
    ...ChatMessages,
    ...SigninMessages,
    ...CreateAccount,
    ...InitialSetupMessages,
    ...ClientList,
    ...TemplateMessages,
    ...UserMessages,
    ...CheetabotMessages,
    ...ChasesMessages,
    ...QuickTextsMessages,
    ...BoardMessages,
    ...IntegrationMessages,
    ...SalesMessages,
    ...DailyClientsMessages,
    ...VisitMessages,
    ...FidelizationMessages,
    ...SatisfactionMessages,
    ...CatalogMessages,
    ...FlowsMessages,
    ...DashboardsMessages,
}

export default messages
