const messages = {
    dash_mainTitle: "Dashboard",
    dash_totalVisits: "Total Visits",
    dash_inThisPeriod: "in this period",
    dash_previousPeriod: "previous period",
    dash_newClients: "New Clients",
    dash_percentageOfYourVisits: "{value} % of your visits",
    dash_percentageInYourPreviousPeriod: "{value} % in your previous period",
    dash_clientsWhoHadCome: "Clients who had come",
    dash_visitsPerDay: "Visits per day",
    dash_quantityTotalPeriod: "{quantity} total period",
    dash_quantityPreviousPeriod: "{quantity} previous period",
    dash_dataMayTakeUpToADayToUpdate: "Data may take up to a day to update",
    dash_visits: "Visits",
    dash_currentPeriod: "Current Period",
    howDidYourNewClientsArrive: "How did your new clients arrive?",
    dashQuantity: "Quantity",
    dashClientVisits: "Client Visits 🙋🏻",
    dashGoToDetailsOfNewClients: "go to clients list",
    dashGoToDetailsExperiences: "go to details of experiences",
    dashExperiences: "Experiences 🤔",
    dashTotalResponses: "Total responses",
    dashOfQuantitySurveysSent: "Of {quantity} surveys sent ({percentage}%)",
    dashGoodExperiences: "Good experiences 🥳",
    dashQuantityOfYourVisits: "{quantity}% of total visits ",
    dashQuantityOfYourResponse: "{quantity}% of the total responses",
    dashPercentageInPreviousPeriod: "{percentage}% in previous period",
    dashBadExperiences: "Bad experiences 😡",
    dashExperiencesByDay: "Experiences per day",
    dashExperiencesCurrentPeriod: "{quantity} Current period experiences",
    dashCouponsSent: "Coupons sent 🎫",
    dashValidatedCoupons: "Validated coupons ✔️",
    dashEstimatedSaleByCoupons: "Estimated sale by coupons 💰",
    dashToPercentagesOfYourVisits: "To {value}% of your visits",
    dashQuantityDeniedPercentage: "{value} denied ( {percentage} %)",
    dashWithATicketOfQuantity: "With a ticket of $ {value}",
    dashPercentageSent: "{value} % sents",
    dashPercentageSentPreviousPeriod: "{value}% sent in the previous period",
    dashValidatedCouponsPerDay: "Coupons validated per day",
    dashLoyalty: "Loyalty",
    dashExperiencesByItem: "Experiences by item",
    dashGoods: "Goods",
    dashBads: "Bads",
    dashBenefitsCollectedForValidatedCoupons: "Benefits collected for validated coupons",
    dashNoBotForThisMetric: "There is no bot for this metric",
    dashExperiencesNoResponse: "No response",
}
export default messages
