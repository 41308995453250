const messages = {
    dash_mainTitle: "Dashboard",
    dash_totalVisits: "Visitas Totais",
    dash_inThisPeriod: "neste período",
    dash_newClients: "Novos Clientes",
    dash_percentageOfYourVisits: "{value} % das suas visitas",
    dash_percentageInYourPreviousPeriod: "{value} % no seu período anterior",
    dash_clientsWhoHadCome: "Clientes que vieram",
    dash_visitsPerDay: "Visitas por dia",
    dash_quantityTotalPeriod: "{quantity} total no período",
    dash_quantityPreviousPeriod: "{quantity} período anterior",
    dash_dataMayTakeUpToADayToUpdate: "Os dados podem levar até um dia para atualizar",
    dash_visits: "Visitas",
    dash_currentPeriod: "Período Atual",
    dash_previousPeriod: "Período Anterior",
    howDidYourNewClientsArrive: "Como chegaram seus novos clientes?",
    dashQuantity: "Quantidade",
    dashClientVisits: "Visitas de Clientes 🙋🏻",
    dashGoToDetailsOfNewClients: "ir para a lista de clientes",
    dashGoToDetailsExperiences: "vá para detalhes de novas experiências",
    dashExperiences: "Experiências 🤔",
    dashTotalResponses: "Respostas totais",
    dashOfQuantitySurveysSent: "De {quantity} pesquisas enviadas ({percentage}%)",
    dashGoodExperiences: "Boas experiências 🥳",
    dashQuantityOfYourVisits: "{quantity}% {quantity}% do total de visitas",
    dashQuantityOfYourResponse: "{quantity}% do total de respostas",
    dashPercentageInPreviousPeriod: "{percentage}% no período anterior",
    dashBadExperiences: "Más experiências 😡",
    dashExperiencesByDay: "Experiências por dia",
    dashExperiencesCurrentPeriod: "{quantity } Experiências do período atual",
    dashCouponsSent: "Cupons enviados 🎫",
    dashValidatedCoupons: "Cupons validados ✔️",
    dashEstimatedSaleByCoupons: "Venda estimada por cupons 💰",
    dashToPercentagesOfYourVisits: "Para {value}% das suas visitas",
    dashQuantityDeniedPercentage: "{value} negados ( {percentage} %)",
    dashWithATicketOfQuantity: "Com um ticket de $ {value}",
    dashPercentageSent: "{value} % enviado",
    dashPercentageSentPreviousPeriod: "{value}% enviado no período anterior",
    dashValidatedCouponsPerDay: "Cupons validados por dia",
    dashLoyalty: "Lealdade",
    dashExperiencesByItem: "Experiências por item",
    dashGoods: "Boas",
    dashBads: "Más",
    dashBenefitsCollectedForValidatedCoupons: "Benefícios coletados para cupons validados",
    dashNoBotForThisMetric: "Não há bot para esta métrica",
    dashExperiencesNoResponse: "Sem resposta",
}
export default messages
