const messages = {
    dash_mainTitle: "Dashboard",
    dash_totalVisits: "Visitas totales",
    dash_inThisPeriod: "en este período",
    dash_previousPeriod: "período anterior",
    dash_newClients: "Clientes nuevos",
    dash_percentageOfYourVisits: "{value} % de tus visitas",
    dash_percentageInYourPreviousPeriod: "{value} % en tu período anterior",
    dash_clientsWhoHadCome: "Clientes que habian venido",
    dash_visitsPerDay: "Visitas por día",
    dash_quantityTotalPeriod: "{quantity} total período",
    dash_quantityPreviousPeriod: "{quantity} período anterior",
    dash_dataMayTakeUpToADayToUpdate: "Los datos pueden tardar hasta un día en actualizarse",
    dash_visits: "Visitas",
    dash_currentPeriod: "Periodo Actual",
    howDidYourNewClientsArrive: "¿Cómo llegaron tus clientes nuevos?",
    dashQuantity: "Cantidad",
    dashClientVisits: "Visitas de clientes 🙋🏻",
    dashGoToDetailsOfNewClients: "ir al listado de clientes",
    dashGoToDetailsExperiences: "ir a detalle de nuevas experiencias",
    dashExperiences: "Experiencias 🤔",
    dashTotalResponses: "Respuestas totales",
    dashOfQuantitySurveysSent: "De {quantity} encuestas enviadas ({percentage}%)",
    dashGoodExperiences: "Buenas experiencias 🥳",
    dashQuantityOfYourVisits: "{quantity}% del total de visitas",
    dashQuantityOfYourResponse: "{quantity}% del total de respuestas",
    dashPercentageInPreviousPeriod: "{percentage}% en período anterior",
    dashBadExperiences: "Malas experiencias 😡",
    dashExperiencesByDay: "Experiencias por día",
    dashExperiencesCurrentPeriod: "{quantity} Experiencias período actual",
    dashCouponsSent: "Cupones enviados 🎫",
    dashValidatedCoupons: "Cupones validados ✔️",
    dashEstimatedSaleByCoupons: "Venta estimada por cupones 💰",
    dashToPercentagesOfYourVisits: "Al {value}% de tus visitas",
    dashQuantityDeniedPercentage: "{value} denegados ( {percentage} %)",
    dashWithATicketOfQuantity: "Con un ticket de $ {value}",
    dashPercentageSent: "{value} % enviados",
    dashPercentageSentPreviousPeriod: "{value}% enviados el en período anterior",
    dashValidatedCouponsPerDay: "Cupones validados por día",
    dashLoyalty: "Fidelización",
    dashExperiencesByItem: "Experiencias por ítem",
    dashGoods: "Buenas",
    dashBads: "Malas",
    dashBenefitsCollectedForValidatedCoupons: "Beneficios cobrados por cupones validados",
    dashNoBotForThisMetric: "No existe un bot para esta métrica",
    dashExperiencesNoResponse: "Sin respuesta",
}
export default messages
